import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import Header from '../components/Header';
import "./UserPage.css";

const UserPage = () => {
  const [user, setUser] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [error, setError] = useState("");
  const [verificationStatus, setVerificationStatus] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8000";
  const apiUrlv1 = `${apiUrl}/api/v1`;
  const mounted = useRef(false);
  const fetchInProgressRef = useRef({
    user: false,
    subscription: false
  });

  const fetchUserData = useCallback(async () => {
    if (fetchInProgressRef.current.user) return;
    fetchInProgressRef.current.user = true;

    try {
      const cookies = document.cookie.split(';');
      const accessToken = cookies
        .find(cookie => cookie.trim().startsWith('access_token='))
        ?.split('=')[1];

      if (!accessToken) {
        window.location.href = '/login';
        return;
      }

      const response = await axios.get(`${apiUrlv1}/users/me`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'accept': 'application/json'
        }
      });

      if (mounted.current) {
        setUser(response.data);
      }
    } catch (error) {
      handleError(error);
    } finally {
      fetchInProgressRef.current.user = false;
    }
  }, [apiUrl]);

  const fetchSubscription = useCallback(async () => {
    if (fetchInProgressRef.current.subscription) return;
    fetchInProgressRef.current.subscription = true;

    try {
      const cookies = document.cookie.split(';');
      const accessToken = cookies
        .find(cookie => cookie.trim().startsWith('access_token='))
        ?.split('=')[1];

      if (!accessToken) return;

      const response = await axios.get(`${apiUrlv1}/subscription/`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'accept': 'application/json'
        }
      });

      if (mounted.current) {
        setSubscription(response.data);
      }
    } catch (error) {
      console.error('Error fetching subscription:', error);
    } finally {
      fetchInProgressRef.current.subscription = false;
    }
  }, [apiUrl]);

  const handleRequestVerification = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/auth/request-verify-token`,
        { email: user.email },
        {
          headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      );
      setVerificationStatus("Verification email sent! Please check your inbox.");
    } catch (err) {
      setVerificationStatus("Failed to send verification email. Please try again later.");
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const handleError = (error) => {
    setError(error.response?.data?.message || "Failed to fetch user data");
    if (error.message === 'No access token found') {
      window.location.href = '/login';
    }
    if ((error.response?.status === 401 && 
         (error.response?.data?.detail === "Unauthorized" || 
          error.response?.data?.detail === "Token has expired. Please log in again")) ||
        error.response?.status === 403) {
      window.location.href = '/login';
    }
  };

  useEffect(() => {
    mounted.current = true;
    fetchUserData();
    fetchSubscription();
    return () => {
      mounted.current = false;
    };
  }, [fetchUserData, fetchSubscription]);

  // 2. Show loading while fetching user data
  if (!user) {
    return (
      <div className="user-page">
        <Header />
        <div className="user-container">
          <div className="loading">Loading...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="user-page">
        <Header />
        <div className="user-container">
          <div className="error-message">{error}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="user-page">
      <Header />
      <div className="user-container">
        <h2>User Profile</h2>
        {/* 2. Show user form */}
        <div className="user-form">
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              value={user.email}
              disabled
            />
          </div>
          
          <div className="form-group">
            <label>Account Status:</label>
            <div className="status-indicator">
              <span className={`status-dot ${user.is_active ? 'active' : 'inactive'}`}></span>
              {user.is_active ? 'Active' : 'Inactive'}
            </div>
          </div>
          
          <div className="form-group">
            <label>Verification Status:</label>
            <div className="status-indicator">
              <span className={`status-dot ${user.is_verified ? 'verified' : 'unverified'}`}></span>
              {user.is_verified ? 'Verified' : 'Not Verified'}
            </div>
          </div>

          {!user.is_verified && (
            <div className="verification-section">
              <button 
                className="verify-button"
                onClick={handleRequestVerification}
              >
                Request Verification Email
              </button>
              {verificationStatus && (
                <div className={`verification-status ${verificationStatus.includes('Failed') ? 'error' : 'success'}`}>
                  {verificationStatus}
                </div>
              )}
            </div>
          )}

          {/* 4. Show subscription form only if we have subscription data */}
          {subscription && (
            <div className="subscription-section">
              <h3>Subscription Details</h3>
              <div className="subscription-info">
                <div className="subscription-row">
                  <span className="label">Plan:</span>
                  <span className="value">{subscription.subscription_type_name.toUpperCase()}</span>
                </div>
                <div className="subscription-row">
                  <span className="label">Status:</span>
                  <span className={`value ${subscription.is_active ? 'active' : 'inactive'}`}>
                    {subscription.is_active ? 'Active' : 'Inactive'}
                  </span>
                </div>
                <div className="subscription-row">
                  <span className="label">Requests per day:</span>
                  <span className="value">
                    {subscription.requests_count} / {subscription.requests_limit}
                  </span>
                </div>
                {subscription.subscription_type_name.toLowerCase() !== 'free' && (
                  <div className="subscription-row">
                    <span className="label">Next Payment:</span>
                    <span className="value">{formatDate(subscription.next_payment_at)}</span>
                  </div>
                )}
                <div className="subscription-row">
                  <span className="label">Created:</span>
                  <span className="value">{formatDate(subscription.created_at)}</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserPage; 