import React, { useState } from 'react';
import Header from '../components/Header';
import './HomePage.css';

const HomePage = () => {
  const [email, setEmail] = useState('');

  const handleGetStarted = (e) => {
    e.preventDefault();
    if (email) {
      // Store email in sessionStorage to access it on the login page
      sessionStorage.setItem('signupEmail', email);
      window.location.href = '/login';
    }
  };

  return (
    <div className="homepage-container">
      <Header />
      <div className="homepage-main">
        <h1>Learn English with AI</h1>
        <h2>Practice speaking and get instant feedback</h2>
        
        <ul className="features-list" style={{textAlign: 'center'}}>
          <li>Practice pronunciation with <span className="highlight">AI voice recognition</span></li>
          <li>Get <span className="highlight">instant feedback</span> on your pronunciation</li>
          <li>Learn at your own pace with <span className="highlight">personalized lessons</span></li>
          <li>Track your progress with detailed <span className="highlight">analytics</span></li>
        </ul>

        <div className="email-signup">
          <input 
            type="email" 
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button onClick={handleGetStarted}>Get Started</button>
        </div>
        
        <p className="login-note">Already have an account? Log in to continue your learning journey.</p>
      </div>
    </div>
  );
};

export default HomePage;
