// VoicePage.js
import React, { useState, useRef } from "react";
import axios from "axios";
import Header from '../components/Header';
import ChatBox from '../components/ChatBox';
import "./VoicePage.css";

const VoicePage = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [audioURL, setAudioURL] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const chatBoxRef = useRef(null);
  const [chatWidth, setChatWidth] = useState(0);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [errorPopup, setErrorPopup] = useState({ show: false, message: '' });

  const handleUnauthorized = () => {
    document.cookie = 'access_token=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    window.location.href = '/login';
  };

  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: 'audio/webm'
      });
      mediaRecorderRef.current.start();

      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorderRef.current.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: "audio/webm" });
        audioChunksRef.current = [];

        const wavBlob = await convertToWav(audioBlob);
        
        const cookies = document.cookie.split(';');
        const accessToken = cookies
          .find(cookie => cookie.trim().startsWith('access_token='))
          ?.split('=')[1];

        if (!accessToken) {
          console.error('No access token found');
          window.location.href = '/login';
          return;
        }

        const formData = new FormData();
        formData.append("file", wavBlob);
        const apiUrl = (process.env.REACT_APP_API_URL || "http://localhost:8000") + "/api/v2";

        try {
          // Upload audio file
          const uploadResponse = await axios.post(`${apiUrl}/audio/upload/`, formData, {
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'accept': 'application/json'
            },
            responseType: 'blob'
          });

          // Create audio URL from response
          const url = URL.createObjectURL(new Blob([uploadResponse.data], { type: "audio/wav" }));
          setAudioURL(url);
          setIsWaiting(false); // Возвращаем кнопку в начальное состояние
          setIsRecording(false); // Убеждаемся, что запись точно остановлена

          // Fetch new messages immediately after successful upload
          if (chatBoxRef.current) {
            chatBoxRef.current.fetchMessages();
          }

        } catch (error) {
          if (error.response?.status === 401) {
            handleUnauthorized();
          } else if (error.response?.status === 403) {
            // Для 403 ошибки читаем содержимое ответа
            const reader = new FileReader();
            reader.onload = () => {
              const errorMessage = JSON.parse(reader.result).detail;
              setErrorPopup({ show: true, message: errorMessage });
            };
            reader.readAsText(error.response.data);
          }
          console.error("Error uploading the file: ", error);
          setIsWaiting(false);
          setIsRecording(false);
        }
      };
    });
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setIsWaiting(true); // Включаем состояние ожидания
    }
  };

  const handleButtonClick = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
    setIsRecording(!isRecording);
  };

  const handleChatWidthChange = (width) => {
    if (isInitialLoad) {
      setIsInitialLoad(false);
    }
    setChatWidth(width);
  };

  const convertToWav = async (blob) => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const arrayBuffer = await blob.arrayBuffer();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    
    const numberOfChannels = audioBuffer.numberOfChannels;
    const length = audioBuffer.length;
    const sampleRate = audioBuffer.sampleRate;
    const wavBuffer = audioContext.createBuffer(numberOfChannels, length, sampleRate);
    
    // Copy the audio data
    for (let channel = 0; channel < numberOfChannels; channel++) {
      const channelData = audioBuffer.getChannelData(channel);
      wavBuffer.copyToChannel(channelData, channel);
    }
    
    // Convert to WAV format
    const wavBlob = await audioBufferToWav(wavBuffer);
    return new Blob([wavBlob], { type: 'audio/wav' });
  };

  const audioBufferToWav = (buffer) => {
    const numberOfChannels = buffer.numberOfChannels;
    const sampleRate = buffer.sampleRate;
    const format = 1; // PCM
    const bitDepth = 16;
    
    let result = new Float32Array(buffer.length * numberOfChannels);
    
    // Interleave channels
    for (let channel = 0; channel < numberOfChannels; channel++) {
      const channelData = buffer.getChannelData(channel);
      for (let i = 0; i < channelData.length; i++) {
        result[i * numberOfChannels + channel] = channelData[i];
      }
    }
    
    // Convert to 16-bit PCM
    const dataLength = result.length * 2;
    const buffer16Bit = new ArrayBuffer(44 + dataLength);
    const view = new DataView(buffer16Bit);
    
    // Write WAV header
    writeString(view, 0, 'RIFF');
    view.setUint32(4, 36 + dataLength, true);
    writeString(view, 8, 'WAVE');
    writeString(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, format, true);
    view.setUint16(22, numberOfChannels, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * numberOfChannels * 2, true);
    view.setUint16(32, numberOfChannels * 2, true);
    view.setUint16(34, bitDepth, true);
    writeString(view, 36, 'data');
    view.setUint32(40, dataLength, true);
    
    // Write audio data
    floatTo16BitPCM(view, 44, result);
    
    return buffer16Bit;
  };

  const writeString = (view, offset, string) => {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  };

  const floatTo16BitPCM = (view, offset, input) => {
    for (let i = 0; i < input.length; i++, offset += 2) {
      const s = Math.max(-1, Math.min(1, input[i]));
      view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
    }
  };

  return (
    <div className="voicepage-container">
      <Header />
      <main 
        className="voicepage-main" 
        style={{ 
          right: `${chatWidth}%`,
          transition: isInitialLoad ? 'none' : 'all 0.3s ease'
        }}
      >
        <h1>Voice Recorder</h1>
        <div className={`recorder ${isRecording ? 'recorder-active' : ''}`}>
          <button
            className={`record-button ${isWaiting ? 'waiting' : ''}`}
            onClick={handleButtonClick}
            disabled={isWaiting}
          >
            {isWaiting ? 'Processing' : isRecording ? 'Stop' : 'Start'}
          </button>
        </div>
        {audioURL && (
          <div className="answer-record">
            <h2>Answer Record</h2>
            <audio controls src={audioURL}></audio>
          </div>
        )}
        
        {errorPopup.show && (
          <div className="error-popup">
            <div className="error-popup-content">
              <button 
                className="error-popup-close"
                onClick={() => setErrorPopup({ show: false, message: '' })}
              >
                ×
              </button>
              <p>{errorPopup.message}</p>
            </div>
          </div>
        )}
      </main>
      <ChatBox ref={chatBoxRef} onWidthChange={handleChatWidthChange} />
    </div>
  );
};

export default VoicePage;
