import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
// import PricingPage from "./pages/PricingPage";
import LoginPage from "./pages/LoginPage";
import VoicePage from "./pages/VoicePage";
import UserPage from "./pages/UserPage";
import VerificationPage from './pages/VerificationPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        {/*<Route path="/pricing" element={<PricingPage />} />*/}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/voice" element={<VoicePage />} />
        <Route path="/user" element={<UserPage />} />
        <Route path="/verify" element={<VerificationPage />} />
      </Routes>
    </Router>
  );
}

export default App;
