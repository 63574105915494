import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './VerificationPage.css';

const VerificationPage = () => {
  const [status, setStatus] = useState('verifying'); // 'verifying', 'success', or 'error'
  const [message, setMessage] = useState('Verifying your email...');
  const location = useLocation();
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8000";

  useEffect(() => {
    const verifyEmail = async () => {
      // Get token from URL parameters
      const params = new URLSearchParams(location.search);
      const token = params.get('token');

      if (!token) {
        setStatus('error');
        setMessage('Verification token is missing.');
        return;
      }

      try {
        const response = await axios.post(
          `${apiUrl}/auth/verify`,
          { token },
          {
            headers: {
              'accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
        );

        setStatus('success');
        setMessage('Email verified successfully!');
        
        // Redirect to user page after 3 seconds
        setTimeout(() => {
          navigate('/user');
        }, 3000);

      } catch (error) {
        if (error.response?.status === 400 && error.response?.data?.detail === "VERIFY_USER_ALREADY_VERIFIED") {
          navigate('/');
          return;
        }
        setStatus('error');
        setMessage(error.response?.data?.detail || 'Verification failed. Please try again.');
      }
    };

    verifyEmail();
  }, [location, navigate, apiUrl]);

  return (
    <div className="verification-page">
      <div className="verification-container">
        <div className={`verification-status ${status}`}>
          {status === 'verifying' && <div className="loading-spinner"></div>}
          {status === 'success' && <div className="success-icon">✓</div>}
          {status === 'error' && <div className="error-icon">✗</div>}
        </div>
        <h2>{message}</h2>
        {status === 'error' && (
          <button onClick={() => navigate('/login')} className="return-button">
            Return to Login
          </button>
        )}
      </div>
    </div>
  );
};

export default VerificationPage;