import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Header.css';
import { useDevice } from '../hooks/useDevice';

const Header = () => {
  const { isMobile } = useDevice();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8000";
  const apiUrlV1 = apiUrl + "/api/v1";
  const currentPath = window.location.pathname;

  const handleUnauthorized = () => {
    // Clear the cookie
    document.cookie = 'access_token=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    setIsLoggedIn(false);
    // Redirect to login
    window.location.href = '/login';
  };

  useEffect(() => {
    const checkLoginStatus = () => {
      const cookies = document.cookie.split(';');
      const accessToken = cookies
        .find(cookie => cookie.trim().startsWith('access_token='))
        ?.split('=')[1];
      setIsLoggedIn(!!accessToken);
    };

    checkLoginStatus();
  }, []);

  const handleLogout = async () => {
    try {
      const cookies = document.cookie.split(';');
      const accessToken = cookies
        .find(cookie => cookie.trim().startsWith('access_token='))
        ?.split('=')[1];

      if (!accessToken) {
        throw new Error('No access token found');
      }

      await axios.post(
        `${apiUrl}/auth/jwt/logout`,
        {},
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          }
        }
      );

      // Clear the cookie
      document.cookie = 'access_token=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      setIsLoggedIn(false);
      
      // Redirect to homepage
      window.location.href = '/';
      
    } catch (err) {
      console.error('Logout failed:', err);
      if (err.response?.status === 401 || err.response?.status === 403) {
        handleUnauthorized();
      }
    }
  };

  const renderNavLinks = () => {
    // Homepage navigation
    if (currentPath === '/') {
      return isLoggedIn ? (
        <>
          <a href="/voice">Voice</a>
          <a href="/user" className="user-button">Profile</a>
          <button onClick={handleLogout} className="logout-button">Log Out</button>
        </>
      ) : (
        <>
          <a href="/voice">Voice</a>
          <a href="/login" className="login-button">Log In</a>
        </>
      );
    }

    // Voice page navigation
    if (currentPath === '/voice') {
      return isLoggedIn ? (
        <>
          <a href="/user" className="user-button">User Profile</a>
          <button onClick={handleLogout} className="logout-button">Log Out</button>
        </>
      ) : (
        <>
          <a href="/login" className="login-button">Log In</a>
        </>
      );
    }

    // User page navigation
    if (currentPath === '/user') {
      return (
        <>
          <a href="/voice">Voice</a>
          <button onClick={handleLogout} className="logout-button">Log Out</button>
        </>
      );
    }

    // Default navigation (for other pages)
    return isLoggedIn ? (
      <>
        <a href="/voice">Voice</a>
        <a href="/user" className="user-button">User Profile</a>
        <button onClick={handleLogout} className="logout-button">Log Out</button>
      </>
    ) : (
      <>
        <a href="/voice">Voice</a>
        <a href="/login" className="login-button">Log In</a>
      </>
    );
  };

  return (
    <header className="homepage-header">
      <a href="/" className="logo-link">
        <div className="logo">
          Learn English Online
          <sup className="beta-tag">beta</sup>
        </div>
      </a>
      <div className={`menu-container ${isMobile ? 'mobile' : 'desktop'}`}>
        {isMobile && (
          <button 
            className={`burger-menu ${isMenuOpen ? 'open' : ''}`} 
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        )}
        <nav className={`homepage-nav ${isMenuOpen ? 'open' : ''}`}>
          {renderNavLinks()}
        </nav>
      </div>
    </header>
  );
};

export default Header; 