// pages/LoginPage.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from '../components/Header';
import "./LoginPage.css";

const LoginPage = () => {
  console.log('Environment variables:', {
    REACT_APP_API_URL: process.env.REACT_APP_API_URL,
    NODE_ENV: process.env.NODE_ENV
  });
  
  const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8000";
  const apiUrlV1 = apiUrl + "/api/v1";
  console.log('API URL:', apiUrl);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Check for email in sessionStorage when component mounts
    const signupEmail = sessionStorage.getItem('signupEmail');
    if (signupEmail) {
      setFormData(prev => ({
        ...prev,
        email: signupEmail
      }));
      // Clear the email from sessionStorage
      sessionStorage.removeItem('signupEmail');
    }
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    
    try {
      // First, try to login
      const loginFormData = new URLSearchParams();
      loginFormData.append('grant_type', 'password');
      loginFormData.append('username', formData.email);
      loginFormData.append('password', formData.password);
      loginFormData.append('scope', '');
      loginFormData.append('client_id', '');
      loginFormData.append('client_secret', '');

      try {
        const loginResponse = await axios.post(
          `${apiUrl}/auth/jwt/login`,
          loginFormData,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'accept': 'application/json'
            }
          }
        );
        
        if (loginResponse.status === 200 && loginResponse.data.access_token) {
          // Store token in cookie
          document.cookie = `access_token=${loginResponse.data.access_token}; path=/`;
          console.log("Login successful, token stored in cookie");
          // Redirect to user page instead of homepage
          window.location.href = '/user';
        }
        
      } catch (loginError) {
        if (loginError.response?.status === 400) {
          // If login fails with 400, try to register
          const registerResponse = await axios.post(
            `${apiUrl}/auth/register`,
            {
              email: formData.email,
              password: formData.password
            },
            {
              headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json'
              }
            }
          );
          
          if (registerResponse.status === 201 && registerResponse.data.id) {
            setShowPopup(true);
            // After showing popup, try to login automatically
            setTimeout(async () => {
              try {
                const autoLoginResponse = await axios.post(
                  `${apiUrl}/auth/jwt/login`,
                  loginFormData,
                  {
                    headers: {
                      'Content-Type': 'application/x-www-form-urlencoded',
                      'accept': 'application/json'
                    }
                  }
                );
                
                if (autoLoginResponse.status === 200 && autoLoginResponse.data.access_token) {
                  document.cookie = `access_token=${autoLoginResponse.data.access_token}; path=/`;
                  window.location.href = '/user';
                }
              } catch (autoLoginError) {
                console.error("Auto-login after registration failed:", autoLoginError);
              }
            }, 3000); // Wait for 3 seconds (same as popup duration)
          }
          
        } else {
          throw loginError;
        }
      }
      
    } catch (err) {
      setError(err.response?.data?.message || "Authentication failed. Please try again.");
    }
  };

  return (
    <div className="login">
      <Header />
      <div className="login-form-container">
        <h2>Log In / Sign Up</h2>
        {error && <div className="error-message">{error}</div>}
        {showPopup && (
          <div className="success-popup">
            Your request is in process
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter your email"
              required
            />
          </div>
          
          <div className="form-group">
            <label>Password:</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Enter your password"
              required
            />
          </div>
          
          <button type="submit" className="submit-button">Sign Up</button>
          <p className="login-note">If you already have an account, we will log you in</p>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
