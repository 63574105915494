import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle, useCallback } from 'react';
import axios from 'axios';
import './ChatBox.css';

const ChatBox = forwardRef(({ onWidthChange }, ref) => {
  const [messages, setMessages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [width, setWidth] = useState(30);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: parseInt(process.env.REACT_APP_PAGE_SIZE) || 5,
    total: 0,
    pages: 0
  });
  const [isLoading, setIsLoading] = useState(false);
  const mounted = useRef(false);
  const chatContentRef = useRef(null);
  const isDraggingRef = useRef(false);
  const fetchInProgressRef = useRef(false);
  const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1` || "http://api:8000/api/v1";

  const fetchMessages = useCallback(async (page = 1, concat = false) => {
    if (fetchInProgressRef.current) return;
    fetchInProgressRef.current = true;
    setIsLoading(true);

    // Сохраняем текущую позицию скролла и высоту контента
    const scrollPosition = chatContentRef.current?.scrollTop;
    const scrollHeight = chatContentRef.current?.scrollHeight;

    try {
      const cookies = document.cookie.split(';');
      const accessToken = cookies
        .find(cookie => cookie.trim().startsWith('access_token='))
        ?.split('=')[1];

      if (!accessToken) {
        console.error('No access token found');
        return;
      }

      const response = await axios.get(`${apiUrl}/messages/`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'accept': 'application/json'
        },
        params: {
          page: page,
          page_size: pagination.pageSize
        }
      });

      if (mounted.current) {
        setMessages(prev => {
          const newMessages = response.data.items;
          if (concat) {
            // После обновления состояния восстанавливаем позицию скролла
            setTimeout(() => {
              if (chatContentRef.current) {
                const newScrollHeight = chatContentRef.current.scrollHeight;
                const heightDiff = newScrollHeight - scrollHeight;
                chatContentRef.current.scrollTop = scrollPosition + heightDiff;
              }
            }, 0);
            return [...prev, ...newMessages];
          }
          return newMessages;
        });
        setPagination(prev => ({
          ...prev,
          page: page,
          total: response.data.total,
          pages: response.data.pages
        }));
      }
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        document.cookie = 'access_token=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        window.location.href = '/login';
      }
      console.error('Error fetching messages:', error);
    } finally {
      fetchInProgressRef.current = false;
      setIsLoading(false);
    }
  }, [apiUrl, pagination.pageSize]);

  const handleScroll = useCallback(() => {
    if (!chatContentRef.current || isLoading) return;

    const { scrollTop, scrollHeight, clientHeight } = chatContentRef.current;
    
    // Если прокрутили почти до верха и есть ещё страницы
    if (scrollTop === 0 && pagination.page < pagination.pages) {
      fetchMessages(pagination.page + 1, true);
    }
  }, [fetchMessages, pagination.page, pagination.pages, isLoading]);

  useEffect(() => {
    const chatContent = chatContentRef.current;
    if (chatContent) {
      chatContent.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (chatContent) {
        chatContent.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (mounted.current) {
      fetchMessages();
    }
  }, [fetchMessages]);

  // Add resize handlers
  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isDraggingRef.current) return;

      const windowWidth = window.innerWidth;
      const newWidth = ((windowWidth - e.clientX) / windowWidth) * 100;
      
      // Constrain width between 20% and 60%
      if (newWidth >= 20 && newWidth <= 60) {
        setWidth(newWidth);
        onWidthChange?.(newWidth); // Notify parent about width change
      }
    };

    const handleMouseUp = () => {
      isDraggingRef.current = false;
      document.body.style.cursor = 'default';
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [onWidthChange]);

  const handleResizeStart = (e) => {
    isDraggingRef.current = true;
    document.body.style.cursor = 'ew-resize';
    e.preventDefault(); // Prevent text selection while dragging
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= pagination.pages) {
      setPagination(prev => ({ ...prev, page: newPage }));
    }
  };

  useImperativeHandle(ref, () => ({
    fetchMessages: async () => {
      if (mounted.current) {
        await fetchMessages();
      }
    }
  }));

  const toggleChat = () => {
    setIsOpen(!isOpen);
    onWidthChange?.(!isOpen ? width : 0);
    
    // Если чат открывается, прокручиваем вниз после короткой задержки
    if (!isOpen) {
      setTimeout(() => {
        if (chatContentRef.current) {
          chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
        }
      }, 100); // Небольшая задержка для анимации открытия
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    }).replace(',', '');
  };

  return (
    <div 
      className={`chat-container ${isOpen ? 'open' : 'closed'}`}
      style={{ width: `${width}%` }}
    >
      <div 
        className="chat-resize-handle"
        onMouseDown={handleResizeStart}
      />
      <div className="chat-toggle" onClick={toggleChat}>
        {isOpen ? '→' : '←'}
      </div>
      <div className="chat-content" ref={chatContentRef}>
        {isLoading && <div className="loading-indicator">Loading...</div>}
        {[...messages].reverse().map((message) => (
          <div key={message.id} className="message-group">
            <div className="user-message">
              <div className="message-bubble">
                {message.user_message}
              </div>
              {message.user_corrected_message && (
                <div className="corrected-message">
                  Corrected: {message.user_corrected_message}
                </div>
              )}
              <div className="message-timestamp">
                {formatDate(message.created_at)}
              </div>
            </div>
            <div className="gpt-message">
              <div className="message-bubble">
                {message.gpt_response}
              </div>
              <div className="message-timestamp">
                {formatDate(message.created_at)}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

export default ChatBox; 